<script setup>

import TransportLayout from "../../../layouts/TransportLayout.vue";
import { onBeforeMount } from "vue";
import { useRoute } from "vue-router/auto";
import useTransportOrder  from "../../../hooks/useTransportOrders.js";
import { setPageTitle } from "@/utils/pageTitle";

const route = useRoute();

const apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

const {
    fetchTransportOrder,
    order
} = useTransportOrder();
onBeforeMount(async () => {
    await fetchTransportOrder(route.params.id);
    setPageTitle(`Transportation Orders // ${order.value.id}`);
});
</script>

<template>
    <TransportLayout v-if="order" :transportOrder="order">
        <div class="map" v-if="order?.pick_zip && order?.drop_zip">
            <iframe
                :src="`https://www.google.com/maps/embed/v1/directions?key=${apiKey}&origin=${order.pick_zip}&destination=${order.drop_zip}`"
                width="600" frameborder="0"
                style="border:0; min-width: 100%;min-height: calc(100vh);" allowfullscreen=""
                aria-hidden="false" tabindex="0"></iframe>
        </div>
        <div v-else class="pa-4 text-disabled">No Map Available</div>
    </TransportLayout>
</template>